var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"stamp"},[_c('div',{staticClass:"body-2 pa-2 mb-1 font-weight-bold",staticStyle:{"background-color":"#E4E4E4","color":"#333333"}},[_vm._v("次回来店予定メモの編集")]),_c('div',{staticClass:"pa-3"},[_c('ValidationObserver',{ref:"observer"},[_c('v-row',[_c('v-col',{staticClass:"fontsize13",staticStyle:{"color":"#9D9D9D","line-height":"22px"},attrs:{"cols":"6"}},[_c('p',{staticClass:"ma-0"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("far fa-calendar-alt")]),_vm._v(" 来店予定日 ")],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.memo_date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.memo_date=$event},"update:return-value":function($event){_vm.memo_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"来店予定日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({staticClass:"pt-0",attrs:{"readonly":"","error-messages":errors,"required":""},model:{value:(_vm.memo_date),callback:function ($$v) {_vm.memo_date=$$v},expression:"memo_date"}},on))]}}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","day-format":function (date) { return new Date(date).getDate(); },"locale":"ja-jp"},model:{value:(_vm.memo_date),callback:function ($$v) {_vm.memo_date=$$v},expression:"memo_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.memo_date)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{staticClass:"fontsize13",staticStyle:{"color":"#9D9D9D","line-height":"22px"},attrs:{"cols":"6"}},[_c('p',{staticClass:"ma-0"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("far fa-clock")]),_vm._v(" 時間 ")],1),_c('ValidationProvider',{attrs:{"name":"時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pt-0",attrs:{"label":"time","single-line":"","clearable":"","type":"time","error-messages":errors,"required":""},model:{value:(_vm.memo_time),callback:function ($$v) {_vm.memo_time=$$v},expression:"memo_time"}})]}}])})],1),_c('v-col',{staticClass:"fontsize13",staticStyle:{"color":"#9D9D9D","line-height":"22px"},attrs:{"cols":"12"}},[_c('p',{staticClass:"ma-0"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-pen")]),_vm._v(" メモ ")],1),_c('ValidationProvider',{attrs:{"name":"メモ","rules":{ required: true, custom_emoji: _vm.custom_emoji }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"pt-0",attrs:{"clearable":"","auto-grow":"","rows":"1","clear-icon":"cancel","value":"","error-messages":errors,"required":""},model:{value:(_vm.memo),callback:function ($$v) {_vm.memo=$$v},expression:"memo"}})]}}])})],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"maincolor-bg default_button mb-5",attrs:{"rounded":""},on:{"click":function($event){return _vm.regist()}}},[_vm._v("メモを保存する")]),_c('br'),_c('v-btn',{staticClass:"caption font-weight-black",staticStyle:{"background-color":"#EFEFEF"},attrs:{"rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.routerBindTo({ name: 'stamp_detail', params: { stamp_id: _vm.init.id, shop_id: _vm.init.shop_id}})}}},[_vm._v("キャンセルする")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{staticClass:"maincolor",attrs:{"size":80,"width":2,"indeterminate":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }