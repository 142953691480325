<template>
    <v-content class="stamp">

        <div class="body-2 pa-2 mb-1 font-weight-bold" style="background-color: #E4E4E4; color: #333333;">次回来店予定メモの編集</div>
        <div class="pa-3">
        <ValidationObserver ref="observer">
        <v-row>
          <v-col cols="6" class="fontsize13" style="color: #9D9D9D; line-height: 22px;">
            <p class="ma-0">
              <v-icon x-small>far fa-calendar-alt</v-icon> 来店予定日
            </p>

            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="memo_date" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on }">
                <ValidationProvider v-slot="{ errors }" name="来店予定日" rules="required">
                <v-text-field class="pt-0" v-model="memo_date" readonly v-on="on" :error-messages="errors" required></v-text-field>
                </ValidationProvider>
              </template>

              <v-date-picker v-model="memo_date" no-title scrollable :day-format="date => new Date(date).getDate()" locale="ja-jp">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(memo_date)">OK</v-btn>
              </v-date-picker>

            </v-menu>
          </v-col>

          <v-col cols="6" class="fontsize13" style="color: #9D9D9D; line-height: 22px;">
            <p class="ma-0">
              <v-icon x-small>far fa-clock</v-icon> 時間
            </p>
            <ValidationProvider v-slot="{ errors }" name="時間" rules="required">
            <v-text-field class="pt-0" v-model="memo_time" label="time" single-line clearable type="time" :error-messages="errors" required></v-text-field>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="fontsize13" style="color: #9D9D9D; line-height: 22px;">
            <p class="ma-0">
              <v-icon x-small>fas fa-pen</v-icon> メモ
            </p>
            <ValidationProvider v-slot="{ errors }" name="メモ" :rules="{ required: true, custom_emoji }">
            <v-textarea class="pt-0" v-model="memo" clearable auto-grow rows="1" clear-icon="cancel" value="" :error-messages="errors" required></v-textarea>
            </ValidationProvider>
          </v-col>

        </v-row>
        </ValidationObserver>

        <div class="text-center">
          <v-btn rounded class="maincolor-bg default_button mb-5" @click="regist()">メモを保存する</v-btn>
          <br>
          <v-btn rounded class="caption font-weight-black" style="background-color:#EFEFEF;" @click.stop="routerBindTo({ name: 'stamp_detail', params: { stamp_id: init.id, shop_id: init.shop_id}})">キャンセルする</v-btn>
        </div>

		</div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

	</v-content>
</template>

<script>
import { required, regex } from 'vee-validate/dist/rules'
import * as CustomRules from '@/utils/validation-custom-rules.js'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

for (const rule in CustomRules) {
  extend(rule, CustomRules[rule])
}

setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})
extend('regex', {
  ...regex,
  message: '絵文字は利用できません。',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      init: [],
      loading: false,
      memo_date: null,
      memo_time: null,
      memo: null,
  }),
  async created () {
    // GET パラメータを取得
    this.getUrlParam()
    this.init = this.storageGet('*')
  },
  methods: {
    regist: function(){
      this.$refs.observer.validate().then(async result => {
        if (result) {
          try {
            //loadingを表示
            this.loading = true
            // 来店メモ登録API実行
            var regist_req = {
              'stamp_id': this.init.stamp_id,
              'memo_date': this.memo_date,
              'memo_time': this.memo_time,
              'memo': this.memo,
              'shop_id': this.init.shop_id,
            }
            var res = await this.apiCall('/stamp/memo', regist_req, 'post');

            if (!res) {
              this.loading = false
              return false;
            }

            this.routerBindTo({ name: 'stamp_detail', query: { stamp_id: this.init.stamp_id, shop_id: this.init.shop_id}})
          } catch(e) {
            this.loading = false
            this.callDialog('エラー', '登録できませんでした。時間をおいて再度登録をしてください', 3);
          }
        }
      });
    }
  }
}
</script>

<style>
.v-date-picker-table > table > tbody > tr > td:nth-child(7) > button > .v-btn__content {
    color:blue;
}
.v-date-picker-table > table > tbody > tr > td:nth-child(1) > button > .v-btn__content {
    color:red;
}
</style>
